import { RootState } from '../store';

// export type tConsultationTypes = 'direct_call' | 'in_app_consultation';
export type tActionTypes = 'addSlot' | 'removeSlot' | 'updateSlotTime' | 'updateItem';

export enum eConsultationTypes {
  direct_call = 'direct_call',
  in_app_consultation = 'in_app_consultation',
  in_app_scheduled = 'in_app_scheduled'
}

export enum eConsultationModes {
  regular = 'regular',
  scheduled = 'scheduled'
}

export interface iConsultationSelected {
  type: eConsultationTypes;
  sessionDuration: string;
}

export interface iNavLinksData {
  logged: {
    Dashboard: string;
    Blog: string;
    Categories: string;
  };
  unlogged: {
    Dashboard: string;
    Blog: string;
    Categories: string;
    'Join us as consultant': string;
  };
}

export interface iScheduleItem {
  id?: number;
  consultant_id?: number;
  day: string;
  slots: {
    start: string;
    end: string
  }[];
  active: string;
  created_at?: string;
  updated_at?: string;
}


export interface iSchedule {
  schedule: iScheduleItem[]
}

export interface iIncomingCallConsultant {
  consultation_id: string;
  call_type: callingOptions;
  pageURL: string;
  pageURLlocal: string;
  pageURLProd: string;
}

export interface iConsultationDetailOfConsultant {
  package_id: number;
  package_name: string;
  package_credits: number;
  duration: number;
  is_enabled: number;
  selected?: boolean;
}

export enum eInboxStatusFilter {
  canceled = 'canceled',
  closed = 'closed',
  pending = 'pending',
  scheduled = 'scheduled',
  all = 'all',
  empty = '',
  accepted = 'accepted',
  active = 'active'
}

export type callingOptions = 'video' | 'audio';

export type tRoomJoin = {
  cnsltId: number;
  type: callingOptions | undefined;
  roomURL?: string;
  clientToken?: string;
  url?: string;
};

export interface iJoinRoom {
  client_meeting_token: string;
  consultant_meeting_token: string;
  room_name: string;
  room_url: string;
  status: boolean;
}

export interface iError {
  message: string;
  status: boolean;
}

export interface iThunkApi {
  state: RootState;
  rejectValue: iError;
}

export interface iCategoryCommon {
  id: number;
  name: string;
  details: string;
  details_en: string;
  details_fr: string;
  name_ar: string;
  name_en: string;
  name_fr: string;
  image_url: string;
  aspect_ratio: number;
  order_no: number;
  color_code: string;
}

export interface iCategory extends iCategoryCommon {
  tag_line: string;
  tag_line_ar: string;
  sub_categories: iCategoryCommon[];
  // pivot?: {
  //   category_id?: number;
  //   consultant_id?: number;
  // };
}

export interface iCategoryWithPhoto extends iCategory {
  photo?: string;
}

export interface iSubscriptionPlan {
  code: string;
  currency: string;
  id: number;
  package_id: number;
  plan_cost: string;
  price: number;
}

export interface iConsultant {
  id: number;
  name_ar: string;
  name_en: string;
  name_fr: string;
  user_id: number;
  price: number;
  rating: number;
  user: {
    id: number;
    status: string;
    photo: string;
  };
  about_en: string;
  about_ar: string;
  about_fr: string;
  status: string;
  photo: string;
  video: string;
  response_time: number;
  consultations: number;
  category: iCategory[];
}

export interface iEducation {
  degree: string;
  university: string;
  year: number;
}

export interface iConsultant_v2 {
  id: number;
  name_ar: string;
  education: iEducation[];
  education_ar: iEducation[];
  name_en: string;
  name_fr: string;
  user_id: number;
  about: string;
  about_en: string;
  category_id: number;
  general_expertise: string[];
  bio_video: string;
  price: number;
  rating: string;
  number_of_consultations: number;
  min_credits: number;
  onjectID: string;
  languages: iLang_V2[];
  main_category_name_ar: string;
  main_category_name_en: string;
  fa_user_status: string;
  photo: string;
  response_time: number;
  consultations: number;
  occupation_ar: string;
  occupation_fr: string;
  occupation: string;
  occupation_en: string;
  objectID: string;
  categories: iCategory[];
  total_reviews: number;
  reviews_1: number;
  reviews_2: number;
  reviews_3: number;
  reviews_4: number;
  reviews_5: number;
  subscription_date?: string;
  type?: 'out-consultant' | 'in-consultant',
  deactivation_date?: string
}

export interface iLang_V2 {
  name: string;
  name_en: string;
  name_ar: string;
}

export interface iConsultantHit extends iConsultant_v2 {
  __position: number;
  index: string;
  _highlightResult: {
    [key: string]: Object;
  };
}

export interface iReviews {
  id: number;
  conselt_reson: string;
  rating_content: string;
  rating_content_en: string;
  rating_content_ar: string;
  rating_content_fr: string;
  rating: number;
  user: {
    id: number;
    status: string;
    photo: string;
    name: string;
  };
  created_at: string;
}

export interface iPaginationMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface iSubscriptionPlan {
  name: string;
  color_code: string;
  amount: string;
  new_amount: string;
  amount_dollar: string;
  end_date: string;
  discount: number;
  qar_amount_after_discount: number;
  in_app_purchase: number;
  package_id: number;
}
